class UserService {
  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
  setUserLogin(login) {
    const user = {
      login: login,
    };
    localStorage.setItem('user', JSON.stringify(user));
  }

  setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }
  async removeUser() {
    localStorage.removeItem('user');
  }
}

export default new UserService();
