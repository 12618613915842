<template>
  <div class="input-field">
    <div v-if="label" class="input-field__label-wrp">
      <span
        class="input-field__label"
        :class="{
          error: (isShowError && errorMessage) || extraErrorMessage,
          active: isOnInput && !errorMessage,
        }"
      >
        {{ label }}
      </span>
    </div>

    <div
      class="input-field__input-wrp"
      :class="{
        error: (isShowError && errorMessage) || extraErrorMessage,
        active: isOnInput,
        without_border: !isBorder,
      }"
    >
      <input
        class="input-field__input"
        :class="{
          error: (isShowError && errorMessage) || extraErrorMessage,
        }"
        :type="isPasswordType && !isShowPassword ? 'password' : 'text'"
        v-bind="$attrs"
        :value="modelValue"
        :placeholder="placeholder"
        :disabled="isDisabled"
        @focus="isOnInput = true"
        @blur="onBlur"
        @input="onInput"
      />
      <button
        v-if="isPasswordType"
        class="input-field__input-icon-btn"
        :class="{
          error: (isShowError && errorMessage) || extraErrorMessage,
        }"
        type="button"
        @click="isShowPassword = !isShowPassword"
      >
        <Icon
          class="input-field__input-icon"
          :name="isShowPassword ? 'hidePassword' : 'showPassword'"
          :is-error="errorMessage ? isShowError : false"
        />
      </button>
      <div class="input-field__input-icon-wrp">
        <Icon v-if="isSearch" :name="'search'" />
      </div>
    </div>

    <div
      v-if="isShowError"
      class="input-field__error-message-wrp"
      :class="{ without_label: !label }"
    >
      <span class="input-field__error-message">
        {{ errorMessage }}
      </span>
    </div>

    <div
      v-if="extraErrorMessage"
      class="input-field__error-message-wrp"
      :class="{ without_label: !label }"
    >
      <span class="input-field__error-message">
        {{ $t(extraErrorMessage) }}
      </span>
    </div>
  </div>
</template>

<script>
const EVENTS = {
  updateModelValue: 'update:modelValue',
  blur: 'blur',
};
import { ref, watchEffect } from 'vue';
import { validate } from '@/js/helpers/validate/validator';
import _isEmpty from 'lodash/isEmpty';
import Icon from '@/vue/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    isBorder: { type: Boolean, default: true },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    isPasswordType: { type: Boolean, default: false },
    isSearch: { type: Boolean, default: false },
    modelValue: { type: [String, Number], default: '' },
    isNumberType: { type: Boolean, default: false },
    validationRules: { type: Object, default: () => {} },
    isValidate: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    fieldName: { type: String, default: '' },
    extraErrorMessage: { type: String, default: '' },
  },
  emits: ['change-valid'],

  setup(props, context) {
    const isShowPassword = ref(false);
    const isOnInput = ref(false);
    const inputValue = ref('');

    const isShowError = ref(false);
    let errorMessage = ref('');

    const onInput = () => {
      if (props.isNumberType)
        event.target.value = event.target.value
          .replace(/^0+(?!\.|$)|[^\d.]/g, '')
          .replace(/^\./, '');
      inputValue.value = event.target.value;
      if (!_isEmpty(props.validationRules)) validateField();
      isShowError.value = true;
      if (props.modelValue === inputValue.value) return;
      context.emit(EVENTS.updateModelValue, inputValue.value);
    };
    const onBlur = () => {
      isOnInput.value = false;
      context.emit(EVENTS.blur);
    };
    watchEffect(() => {
      if (!props.modelValue) return;
      else {
        inputValue.value = props.modelValue;
      }
    });
    const validateField = () => {
      const validateResult = validate(inputValue.value, props.validationRules);
      errorMessage.value = validateResult.errorMessage;
      context.emit('change-valid', {
        fieldName: props.fieldName,
        isValid: validateResult.isValid,
      });
    };
    watchEffect(() => {
      if (!props.isValidate) return;
      isShowError.value = true;
      if (!_isEmpty(props.validationRules)) validateField();
    });

    return {
      isShowPassword,
      isOnInput,
      onInput,
      isShowError,
      errorMessage,
      inputValue,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables';
@import '@/scss/mixins';

.input-field {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  &__error-message {
    font-weight: 100;
    color: $col-error;
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: 0.04rem;

    &-wrp {
      position: absolute;
      top: 6.2rem;

      &.without_label {
        top: 4.5rem;
      }
    }

    @include respond-to($sidebar-hide-bp) {
      &-wrp {
        top: 5.5rem;

        &.without_label {
          top: 3.5rem;
        }
      }
    }
  }

  &__label {
    color: #929fb1;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2rem;

    &.error {
      color: #ff7d80;
    }

    &.active {
      color: #196dea;
    }

    transition: 1ms;
  }

  &__input {
    background-color: $col-app-background-alternative;
    width: 93%;
    text-align: center;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #333333;
    height: 2.7rem;
    border: 0;
    text-size-adjust: 100%;
    &::placeholder {
      font-weight: 300;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: #858585;
    }

    &-wrp {
      display: flex;
      justify-content: space-between;
      border: 0.1rem solid #929fb1;
      border-radius: 1.2rem;
      background-color: $col-app-background-alternative;
      padding: 0.7rem 1rem;

      &.active {
        border: $input-border-active;
      }

      &.error {
        border: 0.5px solid #ff7d80;
      }

      &.without_border {
        border: 0;
      }
    }

    &:focus {
      &::placeholder {
        color: $col-placeholder;
      }
    }

    &-icon {
      &-btn {
        background: none;
        border: 0;
        width: 7%;
      }

      &-wrp {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &.error {
      &::placeholder {
        color: $col-error;
      }

      &:focus {
        &::placeholder {
          color: $col-placeholder;
        }
      }
    }
  }
}

@media (max-width: $sidebar-hide-bp) {
  .input-field__input,
  .input-field__label {
    font-size: 1.4rem;
    height: 2rem;

    &::placeholder {
      font-size: 1.3rem;
    }
  }

  .input-field__input-wrp {
    border-radius: 0.8rem;
  }
}
</style>
