<template>
  <div class="burger-menu">
    <div class="burger" :class="{ 'active': isOpen }" @click="toggleMenu">
      <div class="burger-line"></div>
      <div class="burger-line"></div>
      <div class="burger-line"></div>
    </div>
    <transition name="slide">
      <div v-if="isOpen" class="menu" :class="{ 'slide-in': isOpen, 'slide-out': !isOpen } ">
        <slot />
        <div class="footer">
          <slot name="footer" />
        </div>
      </div>
    </transition>
  </div>
</template>


<script>
import { ref, watch, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
export default {
  name: 'BurgerMenu',
  emits: ['is-open'],
  setup(props, context) {
    const isOpen = ref(false);
    const route = useRoute();
    const toggleMenu = () => {
      isOpen.value = !isOpen.value;
      context.emit('is-open');
    };
    const handleResize = () => {
      if (window.innerWidth > 768) {
        if(isOpen.value) {
          isOpen.value = false;
          context.emit('is-open');
        }
      }
    };

    onMounted(() => {
      window.addEventListener('resize', handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
    });
    watch(
      () => route.path,
      () => {
        if(isOpen.value) {
          isOpen.value = false;
          context.emit('is-open');
        }
      }
    );

    return {
      isOpen,
      toggleMenu,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/app.scss';
.burger-menu {
  position: relative;
}

.burger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 20px;
  cursor: pointer;
}

.burger-line {
  width: 100%;
  height: 2px;
  background-color: white;
}

.burger.active .burger-line:first-child {
  transform: translateY(8px) rotate(45deg);
  background-color: $col-mobile-burger;
}

.burger.active .burger-line:nth-child(2) {
  opacity: 0;
}

.burger.active .burger-line:last-child {
  transform: translateY(-10px) rotate(-45deg);
  background-color: $col-mobile-burger;
}

.menu {
  position: fixed;
  top: 8rem;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $bg-mobile-burger;
  padding: 6rem 0 0;
  display: flex;
  flex-direction: column;
}

.slide-enter-active {
  animation:  slide 0.7s forwards !important;
}

.slide-leave-active {
  animation: slide 0.7s reverse !important;
}

@keyframes slide {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
</style>

