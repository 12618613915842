/**
 * @module validator
 * @description
 * this module is used to generate error message for invalid input data
 * */

import * as validator from './validator-rules';
import i18n from '@/js/i18n/i18n';
import {maxPaymentValidator} from "./validator-rules";

/**
 * @function validate
 * Generate error message if input data invalid
 * @param {string} variable - input data from field
 * @param {boolean} variable - input data from checkbox
 * @param {Object: {rules: { validatorName: string },
 * isValid: Boolean}} value - rules: rules for validation,
 *                            isValid - is valid current data
 * @return {Object:
 * {errorMessage: string, isValid: Boolean}} - validate result object
 * */

export const validate = (variable, value) => {
  const { t } = i18n.global;

  const rulesList = Object.values(value.rules);

  let errorMessage = '';

  rulesList.forEach((item) => {
    if (errorMessage) return '';

    switch (item.validatorName || item) {

      case 'notZero':
        if (!validator.notZero(variable)) {
          errorMessage = t('field-errors.not-zero');
        }
        break;
      case 'required':
        if (!validator.requiredValidator(variable)) {
          errorMessage = t('field-errors.required');
        }
        break;

      // case 'passwordCreate':
      //   const errors = validator.passwordCreate(variable);
      //   if (errors.length > 0) {
      //     errorMessage = t('field-errors.password-create.base') + ' ' + errors.map(err => t(err)).join(', ');
      //
      //   }
      //   break;
      case 'passwordCreate':
        if (!validator.passwordCreate(variable)) {
          errorMessage = t('field-errors.password-create.simple') + ' !@#$%^&*(),.?":{}|<>';
        }
        break;

      case 'cadastralNumber':
        if (!validator.cadastralNumberValidator(variable))
          errorMessage = t('field-errors.cadastral-number');
        break;
      case 'minLength':
        if (!validator.minLengthValidator(variable, item.value))
          errorMessage = `${t('field-errors.min-length')} ${item.value}!`;
        break;
      case 'minPayment':
        if (!validator.minPaymentValidator(variable, item.value)) {
          errorMessage = t('field-errors.min-payment');
        }
        break;
      case 'maxLength':
        if (!validator.maxLengthValidator(variable, item.value))
          errorMessage = `${t('field-errors.max-length')} ${item.value}!`;
        break;
      case 'trueOnly':
        if (!validator.trueOnlyValidator(variable))
          errorMessage = t('field-errors.true-only');
        break;
      case 'youTubeUrl':
        if (!validator.youTubeUrlValidator(variable))
          errorMessage = t('field-errors.you-tube-url');
        break;
      case 'googleMapsUrl':
        if (!validator.googleMapsUrlValidator(variable))
          errorMessage = t('field-errors.google-maps-url');
        break;
      case 'messengerUrl':
        if (!validator.messengerUrlValidator(variable))
        errorMessage = t('field-errors.messenger-url');
        break;
      case 'validateMnemonic':
        if (!validator.validateMnemonic(variable)) {
          errorMessage = 'field-errors.mnemonic';
        }
        break;
      case 'validatePassword':
        if (!validator.validatePassword(variable)) {
          errorMessage = t('field-errors.password');
        }
        break;
      case 'validatePhoneNumber':
        if (!validator.validatePhoneNumber(variable)) {
          errorMessage = t('field-errors.phone-number');
        }
        break;
      case 'isEqual':
        if (!validator.isEqual(variable, item.value)) {
          errorMessage = t('field-errors.equal');
        }
        break;
      case 'maxPayment':
        if (!validator.maxPaymentValidator(variable, item.value)) {
          // errorMessage = t('field-errors.max-payment');
          errorMessage = `${t('field-errors.max-payment')} ${item.value}!`;
        }
        break;
    }
  });

  return errorMessage
    ? { errorMessage: errorMessage, isValid: false }
    : { errorMessage: errorMessage, isValid: true };
};

/**
 * @const required, trueOnly, youTubeUrl, googleMapsUrl, cadastralNumber
 * for import and to specify validation rules in component
 * when you need to validate any field
 * */
export const notZero = {validatorName: 'notZero'}
export const required = { validatorName: 'required' };
export const passwordCreate = { validatorName: 'passwordCreate' };
export const trueOnly = { validatorName: 'trueOnly' };
export const youTubeUrl = { validatorName: 'youTubeUrl' };
export const googleMapsUrl = { validatorName: 'googleMapsUrl' };
export const messengerUrl = { validatorName: 'messengerUrl' }
export const cadastralNumber = { validatorName: 'cadastralNumber' };
export const validateMnemonic = { validatorName: 'validateMnemonic' };
export const password = { validatorName: 'validatePassword' };
export const phoneNumber = { validatorName: 'validatePhoneNumber' };
// export const maxPayment = { validatorName: 'maxPayment' };
/**
 * @function minLength
 * for import and to specify min length for validation field
 * @param {number} value - min number of symbols for current input data
 * */
export const minLength = (value) => {
  return { validatorName: 'minLength', value: value };
};

/**
 * @function maxLength
 * for import and to specify max length for validation field
 * @param {number} value - max number of symbols for current input data
 * */
export const maxLength = (value) => {
  return { validatorName: 'maxLength', value: value };
};
export const isEqual = (value) => {
  return { validatorName: 'isEqual', value: value };
};
export const minPayment = (value) => {
  return { validatorName: 'minPayment', value: value };
};
/**
 * @function maxPayment
 * for import and to specify max value for validation field
 * @param {number} value - max amount USD
 * */
export const maxPayment = (value) => {
  return { validatorName: 'maxPayment', value: value };
};

