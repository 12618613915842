<template>
  <div class="mobile-menu-details">
    <div class="mobile-menu-details__content-wrp" :class="{ hide: !isOpen }">
      <div class="mobile-menu-details__swiper">
        <span />
      </div>
      <div class="mobile-menu-details__re-tokens">
        <ReTokens />
      </div>

      <passport />

      <div class="mobile-menu-details__footer">
        <div class="mobile-menu-details__footer-lang-wrp">
          <language-switcher :color="'#196DEA'" />
        </div>

        <div class="mobile-menu-details__footer-logout-wrp">
          <Logout />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { LOCALES_CONST } from '@/js/i18n/locale.const';
import { computed, ref, watch } from 'vue';
import { languageSwitcher } from '@/js/helpers/languageSwitcher';
import LanguageSwitcher from '@/vue/components/common/LanguageSwitcher';
import Logout from '@/vue/components/common/Logout';
import MiniWallet from '@/vue/components/pages/Wallet/MiniWallet';
import FormMixins from '@/vue/mixins/form.mixins';
import vClickOutside from 'click-outside-vue3';
import Passport from "@/vue/components/common/Passport.vue";
import ReTokens from "@/vue/components/common/ReTokens.vue";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {Passport, LanguageSwitcher, Logout, ReTokens },
  mixins: [FormMixins],
  props: {
    isOpen: { type: Boolean, default: false },
  },
  emits: ['close-details'],

  setup() {
    const { locale } = useI18n({ useScope: 'global' });
    const locales = LOCALES_CONST;
    const findLocale = (value) => {
      return locales.find((locale) => locale.value === value);
    };

    const localeName = computed(
      () => findLocale(currentLocaleValue.value).label
    );
    let currentLocaleValue = ref(locale.value);

    watch(currentLocaleValue, () => {
      languageSwitcher(currentLocaleValue.value);
    });

    return { localeName, currentLocaleValue, locales };
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';

.mobile-menu-details {
  width: 100%;
  height: 100vh;
  display: none;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;

  @include respond-to($tablet) {
    display: flex;
  }

  &__footer {
    padding: 2rem 4rem 2.5rem 4rem;
    display: flex;
    justify-content: space-between;

    &-lang {
      &-wrp {
        display: flex;
      }

      &-select {
        position: relative;
        padding: 0 1rem;
      }
    }

    &-logout {
      font-weight: 400;
      font-size: 1.6rem;
      color: $col-label;
      padding: 0 1rem;

      &-wrp {
        display: flex;
      }
    }
  }

  &__content-wrp {
    width: 30rem;
    height: 36rem;
    background-color: $col-app-background-alternative;
    margin: 0 auto;
    border-radius: 3rem;
    position: relative;

    &.hide {
      top: 1rem;
    }
  }

  &__swiper {
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    & span {
      background-color: $_mobileSwipe;
      width: 4.4rem;
      height: 0.4rem;
      border-radius: 0.1rem;
    }
  }
}
.mobile-menu-details__re-tokens {
  padding: 2rem;
  gap: 2rem;
  display: flex;
  flex-direction: column;
}
</style>
