<template>
  <div
    class="language-switcher"
    :style="{
      color: color,
      '--active-bgc': color === '#196DEA' ? '#196DEA' : '#fff',
      '--active-color': color === '#196DEA' ? '#fff' : '#196DEA',
    }"
  >
    <icon v-if="color === '#fff'" :name="'unauthorized-language-switch'" />
    <icon v-if="color === '#196DEA'" :name="'sidebar-language'" />
    <div class="language-switcher__buttons">
      <button
        class="language-switcher__btn"
        :class="{ active: language === locales.ua }"
        @click="languageSwitcher(locales.ua)"
      >
        {{ locales.ua }}
      </button>
      <button
        class="language-switcher__btn"
        :class="{ active: language === locales.en }"
        @click="languageSwitcher(locales.en)"
      >
        {{ locales.en }}
      </button>
    </div>
  </div>
</template>
<script>
import { languageSwitcher, getLanguage } from '@/js/helpers/languageSwitcher';
import { computed } from 'vue';
import icon from '@/vue/components/common/Icon.vue';
const locales = {
  ua: 'ua',
  en: 'en',
};
export default {
  name: 'LanguageSwitcher',
  components: {
    icon,
  },
  props: {
    color: {
      type: String,
      required: true,
      default: '#fff',
    },
  },
  setup() {
    const language = computed(() => getLanguage());
    return {
      languageSwitcher,
      getLanguage,
      language,
      locales,
    };
  },
};
</script>
<style lang="scss" scoped>
.language-switcher {
  font-size: 13px;
  display: flex;
  align-items: center;
}
.language-switcher__buttons {
  margin-left: 0.8rem;
  display: flex;
}
.language-switcher__btn {
  padding: 0.2rem 0.4rem;
  font-size: 1.3rem;
  font-weight: 400;
  text-transform: uppercase;
  &.active {
    background-color: var(--active-bgc);
    color: var(--active-color);
    border-radius: 0.4rem;
  }
}

.svg {
  fill: red;
}
</style>
