import api from './api';
import UserService from './user.service';
import router from '@/router/index';
import WalletService from './wallet.service';
import { vueRoutes } from "@/router/router-const";

class AuthService {
  login(user) {
    return api
      .post('/identity/v1/auth', {
        login: user.login,
        password: user.password,
      })
      .then((response) => {
        if (response.data.access_token) {
          const userWithCredentials = response.data;
          userWithCredentials.login = user.login;
          UserService.setUser(userWithCredentials);
        }
        return response.data;
      });
  }
  logout = async () => {
    await UserService.removeUser().then(() => {
      WalletService.removePrivateData();
    });
    await router.push(vueRoutes.marketplace);
    location.reload();
  };

  register(user) {
    return api.post('/identity/v1/sign-up', {
      login: user.login,
      password: user.password,
    });
  }
}

export default new AuthService();
