import { createRouter, createWebHistory } from 'vue-router';
import Sidebar from '@/vue/components/common/Sidebar';
import UnauthorizedPanel from '@/vue/components/common/UnauthorizedPanel';
import store from '@/vuex/index';
import authRoutes from './authRoutes';
import RolesService from '@/js/services/roles.service';
const isLoggedIn = store.getters['auth/isLoggedIn'];
import { vueRoutes } from "@/router/router-const";

const routes = [
  ...authRoutes,
  {
    path: '/',
    name: 'Home',
    redirect: vueRoutes.marketplace,
  },

  {
    path: '/market',
    name: vueRoutes.marketplace.name,
    components: {
      default: () => import('@/vue/components/pages/Marketplace/Marketplace'),
      UnauthorizedPanel,
      Sidebar,
    },
    query: {
      price_alt: null,
      country: null,
      city: null,
      rooms: null,
      area: null,
    },
  },
  {
    path: '/real-estate/creation',
    name: vueRoutes.realEstateCreate.name,
    components: {
      default: () =>
        import('@/vue/components/pages/RealEstateCreate/RealEstateCreate'),
      Sidebar,
    },
    meta: {
      requiredAuth: true,
    },
  },
  {
    path: '/real-estate/edit/:id',
    name: vueRoutes.realEstateEdit.name,
    components: {
      default: () =>
        import('@/vue/components/pages/RealEstateCreate/RealEstateCreate'),
      Sidebar,
    },
    meta: {
      requiredAuth: true,
    },
  },
  {
    path: '/real-estate/creation/sales-propose',
    name: vueRoutes.createFormSalesProposal.name,
    components: {
      default: () =>
        import(
          '@/vue/components/pages/RealEstateCreate/createFormSalesPropose'
        ),
      Sidebar,
    },
    meta: {
      requiredAuth: true,
    },
  },
  {
    path: '/about',
    name: vueRoutes.about.name,
    components: {
      // default: () => import('@/vue/components/pages/Settings/About'),
      UnauthorizedPanel,
    },
    // meta: {
    //   showHeader: true,
    // },
  },
  {
    path: '/faq',
    name: vueRoutes.FAQ.name,
    components: {
      default: () => import('@/vue/components/pages/Settings/FAQ'),
      UnauthorizedPanel,
    },
    meta: {
      showHeader: false,
    },
  },
  {
    path: '/contacts',
    name: vueRoutes.contacts.name,
    components: {
      default: () => import('@/vue/components/pages/Settings/TechSupport'),
      UnauthorizedPanel,
    },
    // props: {
    //   isAuthorized: false
    // }
  },
  {
    path: '/settings',
    name: vueRoutes.settings.name,
    component: () => import('@/vue/components/pages/Settings/Settings'),
    redirect: vueRoutes.profile,
  },
  {
    path: '/settings/tech-support',
    name: vueRoutes.techSupport.name,
    components: {
      default: () => import('@/vue/components/pages/Settings/TechSupport'),
      Sidebar,
    },
    meta: {
      requiredAuth: true,
      showHeader: true,
    },
  },
  {
    path: '/settings/about',
    name: vueRoutes.settingsAbout.name,
    components: {
      default: () => import('@/vue/components/pages/Settings/About.vue'),
      Sidebar,
      UnauthorizedPanel,
    },
    meta: {
      requiredAuth: true,
      showHeader: true,
    },
  },
  {
    path: '/settings/faq',
    name: vueRoutes.faq.name,
    components: {
      default: () => import('@/vue/components/pages/Settings/FAQ'),
      Sidebar,
      UnauthorizedPanel,
    },
    meta: {
      requiredAuth: true,
      showHeader: true,
    },
  },
  {
    path: '/settings/kyc',
    name: vueRoutes.kyc.name,
    components: {
      default: () => import('@/vue/components/pages/Settings/KYC'),
      Sidebar,
    },
    meta: {
      requiredAuth: true,
    },
  },
  {
    path: '/settings/profile',
    name: vueRoutes.profile.name,
    components: {
      default: () => import('@/vue/components/pages/Settings/Profile/Profile'),
      Sidebar,
    },
    meta: {
      requiredAuth: true,
    },
  },
  {
    path: '/real-estate',
    name: vueRoutes.realEstate.name,
    components: {
      default: () => import('@/vue/components/pages/Wallet/Wallet'),
      Sidebar,
    },
    meta: {
      requiredAuth: true,
    },
    redirect: vueRoutes.ownedRealEstate,
    children: [
      {
        path: 'owned-real-estate',
        name: vueRoutes.ownedRealEstate.name,
        components: {
          default: () => import('@/vue/components/pages/Wallet/Wallet'),
          Sidebar,
        },
      },
      {
        path: 'on-sale',
        name: vueRoutes.realEstateOnSale.name,
        components: {
          default: () => import('@/vue/components/pages/Wallet/Wallet'),
          Sidebar,
        },
      },
      {
        path: 'partially-owned',
        name: vueRoutes.realEstateInstallment.name,
        components: {
          default: () => import('@/vue/components/pages/Wallet/Wallet'),
          Sidebar,
        },
      },
      {
        path: 'withdrawn',
        name: vueRoutes.realEstateWithdrawn.name,
        components: {
          default: () => import('@/vue/components/pages/Wallet/Wallet'),
          Sidebar,
        },
      },
      // {
      //   path: 'all',
      //   name: vueRoutes.realEstateAll.name,
      //   components: {
      //     default: () => import('@/vue/components/pages/Wallet/Wallet'),
      //     Sidebar,
      //   },
      // }
    ],
  },
  {
    path: '/real-estate/cards',
    name: vueRoutes.cards.name,
    component: () =>
      import('@/vue/components/pages/RealEstateDetails/OfferCard'),
    meta: {
      requiredAuth: true,
    },
  },

  {
    path: '/real-estate/my-estate',
    name: vueRoutes.myEstate.name,
    redirect: vueRoutes.settings,
  },
  {
    path: '/real-estate/details/:id',
    name: vueRoutes.realEstateDetails.name,
    components: {
      default: () =>
        import('@/vue/components/pages/RealEstateDetails/RealEstateDetails'),
      Sidebar,
      UnauthorizedPanel,
    },
  },
  {
    path: '/market/:id',
    name: vueRoutes.marketplaceRealEstateDetails.name,
    components: {
      default: () =>
        import('@/vue/components/pages/RealEstateDetails/RealEstateDetails'),
      Sidebar,
      UnauthorizedPanel,
    },
  },
  {
    path: '/real-estate/part-purchase',
    name: vueRoutes.partPurchase.name,
    redirect: vueRoutes.settings,
  },
  {
    path: '/real-estate/offer/iscomissioned=:isComissioned',
    name: vueRoutes.createOffer.name,
    components: {
      default: () => import('@/vue/components/pages/CreateOffer/CreateOffer'),
      Sidebar,
    },
    props: true,
  },
  {
    path: '/real-estate/withdrawn',
    name: vueRoutes.withdrawn.name,
    redirect: vueRoutes.settings,
  },
  {
    path: '/currencies',
    name: vueRoutes.currency.name,
    components: {
      default: () =>
        import('@/vue/components/pages/Currencies/CurrenciesPage.vue'),
      Sidebar,
    },
    meta: {
      requiredAuth: true,
    },
    // currency-usd
    redirect: vueRoutes.currencyUsd,
    children: [
      {
        path: 'platform',
        name: vueRoutes.currencyUsd.name,
        components: {
          default: () =>
            import('@/vue/components/pages/Currencies/CurrenciesPage.vue'),
          Sidebar,
        },
        meta: {
          requiredAuth: true,
        },
      },
      {
        path: 'metamask',
        name: vueRoutes.currencyRe.name,
        components: {
          default: () =>
            import('@/vue/components/pages/Currencies/CurrenciesPage.vue'),
          Sidebar,
        },
        meta: {
          requiredAuth: true,
        },
      },
    ],
  },
  {
    path: '/transaction-history',
    name: vueRoutes.transactionHistory.name,
    components: {
      default: () =>
        import('@/vue/components/pages/TransactionHistory/TransactionHistory'),
      Sidebar,
    },
    meta: {
      requiredAuth: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: vueRoutes.notFound.name,
    components: {
      default: () =>
        import('@/vue/components/pages/PageNotFound/PageNotFound.vue'),
      Sidebar,
      UnauthorizedPanel,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters['auth/isLoggedIn'];
  const isKycProceeded = RolesService.getKycProceedStatus();
  const isRecovered = store.getters['auth/getPrivateData']?.recovered;
  // unable access for routes required login for unlogged users

  const { meta } = to.matched[0];

  // Redirect logged-out users from routes that require authentication
  if (meta.requiredAuth && !isLoggedIn) {
    next('/market');
    // next(vueRoutes.marketplace.name);

    return;
  } else if (to.path !== '/recovery/seed' && isLoggedIn && isRecovered) {
    next('/recovery/seed');
    return;
  }

  // Redirect logged-in users from routes that don't require authentication
  else if (meta.requiredVisitor && isLoggedIn) {
    next('/market');
    // next(vueRoutes.marketplace.name);

    return;
  }

  // Redirect users who haven't completed the mnemonic process
  else if (
    !meta.wallet &&
    isLoggedIn &&
    !localStorage.getItem('private_data')
  ) {
    next('/authorization/mnemonic');
    // next(vueRoutes.mnemonic.name);

    return;
  }

  // Redirect users who haven't completed the KYC process
  else if (
    !meta.toProceedKyc &&
    !isKycProceeded &&
    localStorage.getItem('private_data')
  ) {
    next('/authorization/kyc');
    // next(vueRoutes.authKYC.name);

    return;
  }
  // Allow access to the route
  next();
});

export default router;
