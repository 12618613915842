<template>
  <div v-if="isLoggedIn" class="sidebar-wrapper">
    <div class="sidebar">
      <div class="sidebar__aside">
        <div class="sidebar__content">
          <section class="sidebar__logo-section">
            <router-link to="/">
              <img
                class="sidebar__logo"
                src="@/assets/branding/logo/Frame1.png"
                alt=""
              />
            </router-link>
          </section>

          <section class="sidebar__links-section">
            <nav class="sidebar__links-group">
              <router-link :to="vueRoutes.marketplace" class="sidebar__link">
                <div class="sidebar__link-svg">
                  <icon name="sidebar-marketplace" />
                </div>
                {{ $t('sidebar.marketplace') }}
              </router-link>

              <router-link
                :to="vueRoutes.realEstate"
                class="sidebar__link"
                :class="{ active: isRealEstate }"
              >
                <div class="sidebar__link-svg">
                  <icon name="sidebar-estate" />
                </div>
                {{ $t('sidebar.real-estate') }}
              </router-link>

              <router-link :to="vueRoutes.currency" class="sidebar__link">
                <div class="sidebar__link-svg">
                  <icon name="sidebar-wallet" />
                </div>
                {{ $t('sidebar.cryptocurrency') }}
              </router-link>

              <router-link :to="vueRoutes.transactionHistory" class="sidebar__link">
                <div class="sidebar__link-svg">
                  <icon name="sidebar-history" />
                </div>
                {{ $t('sidebar.transaction-history') }}
              </router-link>

              <router-link
                :to="vueRoutes.settings"
                class="sidebar__link"
                :class="{ active: isSettings }"
              >
                <div class="sidebar__link-svg">
                  <icon name="sidebar-settings" />
                </div>
                {{ $t('sidebar.settings') }}
              </router-link>
            </nav>
          </section>
        </div>
        <section class="sidebar__re-tokens">
          <ReTokens />
        </section>
        <section class="sidebar__passport-section">
          <nav class="sidebar__links-group">
            <Passport class="sidebar__link-passport" />
            <div class="sidebar__links-controls">
              <language-switcher :color="'#196DEA'" />
              <Logout />
            </div>
          </nav>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import icon from '@/vue/components/common/Icon.vue';
import FormMixins from '@/vue/mixins/form.mixins';
import Passport from '@/vue/components/common/Passport.vue';
import { useStore } from 'vuex';
import Logout from '@/vue/components/common/Logout';
import { useI18n } from 'vue-i18n';
import { ref, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import { LOCALES_CONST } from '@/js/i18n/locale.const';
import { languageSwitcher } from '@/js/helpers/languageSwitcher';
import LanguageSwitcher from '@/vue/components/common/LanguageSwitcher';
import { vueRoutes } from "@/router/router-const";
import ReTokens from "@/vue/components/common/ReTokens.vue";
export default {
  name: 'Sidebar',
  components: {
    icon,
    Passport,
    Logout,
    LanguageSwitcher,
    ReTokens,
  },
  mixins: [FormMixins],
  setup() {
    const { locale } = useI18n({ useScope: 'global' });
    const store = useStore();
    const locales = LOCALES_CONST;
    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);
    const findLocale = (value) => {
      return locales.find((locale) => locale.value === value);
    };
    let currentLocaleValue = ref(locale.value);

    const localeName = computed(
      () => findLocale(currentLocaleValue.value).label
    );

    watch(currentLocaleValue, () => {
      languageSwitcher(currentLocaleValue.value);
    });

    const route = useRoute();

    let isSettings = computed(() => {
      return route.path.includes(vueRoutes.settings.name);
    });
    let isRealEstate = computed(() => {
      return route.path.includes(vueRoutes.realEstate.name);
    });

    return {
      localeName,
      currentLocaleValue,
      locales,
      isSettings,
      isRealEstate,
      isLoggedIn,
      vueRoutes,
    };
  },
};
</script>
<style lang="scss">
@import '@/scss/app.scss';

.sidebar__link-passport {
  padding: 0 1.3rem;
}

.sidebar__links-controls {
  padding: 0 1.3rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2.7rem;
  gap: 1.9rem;
  height: 6rem;
}

.sidebar__passport-section {
  flex: 0 0 auto;
}

.sidebar {
  position: fixed;
  height: 100%;
  padding: 3.5rem 0 5rem;
  background-color: #e9eef6;
  min-height: 100%;
  min-width: 24.1rem;
  overflow: clip;

  @media (max-width: $sidebar-hide-bp) {
    display: none;
  }
  @media (max-height: 650px) {
    overflow-y: auto;
  }

  &__logo {
    width: 10rem;
    height: 10rem;
    object-fit: contain;
  }

  &__content {
    flex: 0 0 auto;

    @media (max-height: 650px) {
      flex: 0;
    }
  }

  &__language {
    display: flex;
  }

  &__aside {
    width: 24.1rem;
    min-height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 0 auto;
    justify-content: space-between;
    overflow-y: scroll;
  }

  &__logo-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__links-section {
    margin: 7.3rem 0 2rem 0;
    width: 100%;
    flex: 1 0 auto;

    @media (max-height: 650px) {
      margin: 3rem 0 2rem;
    }
  }

  &__links-group {
    padding: 0 2rem;
    width: 100%;
  }

  &__link {
    font-weight: 300;
    display: flex;
    padding: 1.25rem 1.7rem;
    white-space: nowrap;
    font-size: 1.5rem;
    width: 100%;
    align-items: center;
    border-radius: 1rem;
    color: $sidebar-col-link;

    & svg {
      stroke: #196dea;
    }

    &.router-link-active,
    &.active {
      background-color: $sidebar-bg-col;
      color: $sidebar-col-active;

      & svg {
        stroke: $sidebar-col-active;
        fill: none;
      }
    }
  }

  &__link-svg {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
  }
}
.sidebar__re-tokens {
  display: flex;
  padding: 3rem 3.4rem 2rem;
  flex-direction: column;
  gap: 2rem;
}
.sidebar__re-tokens-item {
  border-left: 1px solid #196DEA;
  padding-left: 1rem;
}
.sidebar__re-tokens-title{
  color: #858585;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.15px;
}
.sidebar__re-tokens-balance {
  color: #333;
  font-size: 16px;
  font-weight: 400;
}
//.sidebar__aside {
//  scrollbar-width: thin; /* Уменьшает ширину */
//  scrollbar-color: #888 $col-app-background; /* Цвет ползунка и фона */
//}
.sidebar__aside::-webkit-scrollbar {
  width: 7px; /* Ширина скроллбара */
}

.sidebar__aside::-webkit-scrollbar-track {
  background: $col-app-background; /* Фон скроллбара */
}

.sidebar__aside::-webkit-scrollbar-thumb {
  background: #888; /* Цвет ползунка */
  border-radius: 1px; /* Скругление краев */
}

.sidebar__aside::-webkit-scrollbar-thumb:hover {
  background: #747070; /* Цвет ползунка при наведении */
}
.sidebar__aside::-webkit-scrollbar-button {
  display: none; /* Прячем кнопки */
}
</style>
