import api from '@/js/services/api';
import RolesService from '@/js/services/roles.service';

export const roles = {
  namespaced: true,
  state: {
    kycProceeded: false,
    rolesData: null,
  },
  actions: {
    // async fetchRolesData({ commit }, userId) {
    //   const response = await api.get(
    //     `tx/v1/roles/crr?filter[user_id]=${userId}`
    //   );
    //
    //   if (response.data.data && response.data.data.length > 0) {
    //     await commit('setKycProceeded', true);
    //   }
    //   commit('setRolesData', response.data);
    // },
      updateKycProceeded({ commit }, value) {
        commit('setKycProceeded', value);
      },
      async fetchRolesData({ commit }, userId) {
        try {

          const rolesResponse = await api.get(
            `tx/v1/roles/crr?filter[user_id]=${userId}`
          );

          const identityResponse = await api.get(
            `identity/v1/roles/${userId}`
          );
          rolesResponse.data.data[0].role = identityResponse.data.role

          if (rolesResponse.data.data && rolesResponse.data.data.length > 0) {
            commit('setKycProceeded', true);
          }

          commit('setRolesData', rolesResponse.data);
        } catch (error) {
          console.error('Error fetching roles data:', error);
        }
      },
  },
  mutations: {
    setKycProceeded(state, value) {
      state.kycProceeded = value;
      RolesService.setKycProceedStatus(value);
    },
    setRolesData(state, data) {
      state.rolesData = data;
    },
  },

  getters: {
    kycProceeded: (state) => state.kycProceeded,
    rolesData: (state) => {
      // debugger
      return state.rolesData
    },
  },
};
