<template>
  <div class="notifications-list">
    <notification-message
      v-for="notification in notifications"
      :key="notification.id"
      class="notifications-list__item"
      :notification="notification"
    />
  </div>
</template>

<script>
import NotificationMessage from './NotificationMessage';
import { useStore } from 'vuex';
import { computed } from 'vue';
export default {
  components: {
    NotificationMessage,
  },
  setup() {
    const store = useStore();
    return {
      notifications: computed(
        () => store.getters['notification/notifications']
      ),
    };
  },
  //computed: mapState("notification", ["notifications"])
};
</script>

<style scoped lang="scss">
@import '@/scss/variables.scss';
.notifications-list {
  position: fixed;
  top: 0;
  right: 0;
  padding: 1.1rem 1.7rem;
  z-index: $z-index-notification;
  &__item {
    margin-bottom: 1.5rem;
  }
}
</style>
