<template>
    <div :class="{ active:isHeader }" class="re-tokens__item">
      <div class="re-tokens__title">
        {{ $t('re-tokens.amount') }}
      </div>
      <div class="re-tokens__balance">
        {{ formattedTotalRe }}
      </div>
    </div>
    <div :class="{ active:isHeader }" class="re-tokens__item">
      <div class="re-tokens__title">
        {{ $t('re-tokens.for-buy') }}
      </div>
      <div class="re-tokens__balance">
        {{ formattedRE }}
      </div>
    </div>
</template>

<script>
import {computed} from "vue";
import {formatBalanceRE} from "@/js/filters/formatBalance";
import {useStore} from "vuex";
export default {
  name: 'ReTokens',
  props: {
    isHeader : {type: Boolean, default: false }
  },
  setup() {
    const store = useStore();
    const formattedRE = computed(() => {
      const balanceRE = store.getters['balances/getBalances'].balanceRE;
      return formatBalanceRE(balanceRE);
    });
    const formattedTotalRe = computed(() => {
      const balanceRE = store.getters['balances/getBalances'].totalRE;
      return formatBalanceRE(balanceRE);
    });

    return {
      formattedRE,
      formattedTotalRe
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/app.scss';
.re-tokens__item {
  border-left: 1px solid #196DEA;
  padding-left: 1rem;
}
.re-tokens__title{
  color: #858585;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.15px;
}
.re-tokens__balance {
  color: #333;
  font-size: 16px;
  font-weight: 400;
}
.active {
    border-left: 1px solid #196DEA;
    border-radius: 6px;
    background-color: white;
    flex: 1 0 0;
    padding: 0.6rem 1rem;
  }
</style>
