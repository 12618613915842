/**
 * @helper validator-rules
 * @description
 * this helper is used to validate the input data
 * according to the specified rules
 * */
import * as crypto from '@/js/modules/crypto.module';
import store from '@/vuex/index';
import { isValidNumber } from 'libphonenumber-js';
import { auth } from '@/vuex/auth.module';
/**
 * @function requiredValidator
 * validate the input for null
 * @param {string} item - data of validation
 * @return {boolean} - whether the input data is valid
 *                     according to the validation rules
 * */

export const requiredValidator = (item) => {
  const reg = new RegExp('[^\\s*$\n]');
  return reg.test(item);
};
// export const passwordCreate = (item) => {
//   const isMinLength = item.length >= 8;
//   const regUppercase = new RegExp('[A-Z]'); //RegExp('[A-ZА-Я]');
//   const regDigit = new RegExp('\\d');
//   const regSpecial = new RegExp('[!@#$%^&*(),.?":{}|<>]')
//   // return regUppercase.test(item) && regDigit.test(item) && regSpecial.test(item);
//   let errors = [];
//
//   if (!isMinLength) errors.push('field-errors.password-create.characters');
//   if (!regUppercase.test(item)) errors.push('field-errors.password-create.upper');
//   if (!regDigit.test(item)) errors.push('field-errors.password-create.number');
//   if (!regSpecial.test(item)) errors.push('field-errors.password-create.special');
//
//   return errors;
// };
export const passwordCreate = (item) => {
  const isMinLength = item.length >= 8;
  const regUppercase = new RegExp('[A-Z]');
  const regDigit = new RegExp('\\d');
  const regSpecial = new RegExp('[!@#$%^&*(),.?":{}|<>]');

  return isMinLength && regUppercase.test(item) && regDigit.test(item) && regSpecial.test(item);
};
// export const passwordCreate = (item) => {
//   const isMinLength = item.length >= 8;
//   const regUppercase = new RegExp('[A-Z]');
//   const regDigit = new RegExp('\\d');
//   const regSpecial = new RegExp('[!@#$%^&*(),.?":{}|<>]');
//
//   let errors = [];
//   console.log(`Checking password: ${item}`);
//
//   if (!isMinLength) {
//     errors.push('field-errors.password-create.characters');
//     console.log('Error: Minimum length not met.');
//   }
//   if (!regUppercase.test(item)) {
//     errors.push('field-errors.password-create.upper');
//     console.log('Error: No uppercase letter found.');
//   }
//   if (!regDigit.test(item)) {
//     errors.push('field-errors.password-create.number');
//     console.log('Error: No digit found.');
//   }
//   if (!regSpecial.test(item)) {
//     errors.push('field-errors.password-create.special');
//     console.log('Error: No special character found.');
//   }
//
//   return errors;
// };

/**
 * @function minLengthValidator
 * validate the input for min length
 * @param {string} item - data of validation
 * @param {number} value - min length value
 * @return {boolean} - whether the input data is valid
 *                     according to the validation rules
 * */

export const minLengthValidator = (item, value) => {
  return item.length >= value;
};

export const minPaymentValidator = (item, price) => {
  return item >= price - price * 0.7;
};
/**
 * @function maxLengthValidator
 * validate the input for max length
 * @param {string} item - data of validation
 * @param {number} value - max length value
 * @return {boolean} - whether the input data is valid
 *                     according to the validation rules
 * */

export const maxLengthValidator = (item, value) => {
  return item.length <= value;
};

/**
 * @function trueOnlyValidator
 * validate the input for true value
 * @param {boolean} item - data of validation
 * @return {boolean} - whether the input data is valid
 *                     according to the validation rules
 * */

export const trueOnlyValidator = (item) => {
  return !!item;
};

/**
 * @function youTubeUrlValidator
 * validate the input for YouTube link
 * @param {string} item - data of validation
 * @return {boolean} - whether the input data is valid
 *                     according to the validation rules
 * */

export const youTubeUrlValidator = (item) => {
  if (item === '') {
    return true;
  }
  const reg = new RegExp(/^https:\/\/(?:www\.)?|^$/);
  if (item.length >= 150) return false;
  return reg.test(item);
};

/**
 * @function googleMapsUrlValidator
 * validate the input for Google Maps link
 * @param {string} item - data of validation
 * @return {boolean} - whether the input data is valid
 *                     according to the validation rules
 * */

export const googleMapsUrlValidator = (item) => {
  const reg = new RegExp(/^https:\/\/(?:www\.)?/);
  return reg.test(item);
};

/**
 * @function messengerUrlValidator
 * validate the input for Google Maps link
 * @param {string} item - data of validation
 * @return {boolean} - whether the input data is valid
 *                     according to the validation rules
 * */

export const messengerUrlValidator = (item) => {
  if (item === '') {
    return true;
  }
  const reg = new RegExp(/^https:\/\/(?:www\.)?/);
  return reg.test(item);
};

/**
 * @function cadastralNumberValidator
 * validate the input for cadastral number
 * @param {string} item - data of validation
 * @return {boolean} - whether the input data is valid
 *                     according to the validation rules
 * */

export const cadastralNumberValidator = (item) => {
  const reg = new RegExp(/[\%\/\\\&\?\,\'\;\!\-\+\!\@\#\$\^\*\)\(\A-zА-яЁё]/);
  return !reg.test(item);
};

export const validateMnemonic = async (mnemonic) => {
  let seed = await crypto.getSeedFromMnemonicInBase64(
    mnemonic.value,
    store.state.auth.user.login
  );
  const hdkey = crypto.getHDkey(seed);
  let wallet = crypto.getPublickKey(hdkey);
  return wallet === store.state.auth.private_data.wallet;
};

export const validatePassword = (password) => {
  const encodedData = auth.getters.getPrivateData(auth.state).encoded_data;
  const seed = crypto.decryptAES(encodedData, password);
  return seed !== 'invalid_password';
};

export const validatePhoneNumber = (phoneNumber) => {
  const firstPhoneNumbers = phoneNumber.slice(0, 4);
   if(firstPhoneNumbers === '+380' && phoneNumber.length === 13) {
     return isValidNumber(phoneNumber, 'UA');
   };
  // return true;
}

export const isEqual = (value, equal) => {
  return value === equal;
};

export const notZero = (value) => {
  return value > 0;
};

export const maxPaymentValidator = (item, value) => {
  return item <= value
}
