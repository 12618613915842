export const LOCALES_CONST = [
  {
    label: 'UA',
    value: 'ua',
  },
  {
    label: 'ENG',
    value: 'en',
  },
];
