import InputField from '@/vue/components/fields/InputField';
import SelectField from '@/vue/components/fields/SelectField';
import DropdownField from '@/vue/components/fields/DropdownField';
import MultiRangeSlider from '@/vue/components/fields/MultiRangeSlider';
import TextareaField from '@/vue/components/fields/TextareaField';
import CheckboxField from '@/vue/components/fields/CheckboxField';

export default {
  components: {
    InputField,
    SelectField,
    DropdownField,
    MultiRangeSlider,
    TextareaField,
    CheckboxField,
  },
};
