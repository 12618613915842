class WalletService {
  setPrivateData(private_data) {
    localStorage.setItem('private_data', JSON.stringify(private_data));
  }
  removePrivateData() {
    localStorage.removeItem('private_data');
  }
}

export default new WalletService();
