<template>
  <div class="textarea-field">
    <div v-if="label" class="textarea-field__label-wrp">
      <span
        class="textarea-field__label"
        :class="{
          error: (isShowError && errorMessage) || extraErrorMessage,
        }"
      >
        {{ label }}
      </span>
    </div>

    <div
      class="textarea-field__input-wrp"
      :class="{
        error: (isShowError && errorMessage) || extraErrorMessage,
        active: isOnInput,
        without_border: !isBorder,
      }"
    >
      <textarea
        class="textarea-field__input"
        :class="{
          error: (isShowError && errorMessage) || extraErrorMessage,
        }"
        v-bind="$attrs"
        :value="modelValue"
        :placeholder="placeholder"
        :disabled="isDisabled"
        @focus="isOnInput = true"
        @blur="isOnInput = false"
        @input="onInput"
      />
    </div>

    <div v-if="isShowError" class="textarea-field__error-message-wrp">
      <span class="textarea-field__error-message">
        {{ errorMessage }}
      </span>
    </div>

    <div v-if="extraErrorMessage" class="textarea-field__error-message-wrp">
      <span class="textarea-field__error-message">
        {{ $t(extraErrorMessage) }}
      </span>
    </div>
  </div>
</template>

<script>
const EVENTS = {
  updateModelValue: 'update:modelValue',
};
import { ref, watchEffect } from 'vue';
import { validate } from '@/js/helpers/validate/validator';
import _isEmpty from 'lodash/isEmpty';

export default {
  props: {
    isBorder: { type: Boolean, default: true },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    modelValue: { type: [String, Number], default: '' },
    validationRules: { type: Object, default: () => {} },
    isValidate: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    fieldName: { type: String, default: '' },
    extraErrorMessage: { type: String, default: '' },
  },
  emits: ['change-valid'],

  setup(props, context) {
    const isShowPassword = ref(false);
    const isOnInput = ref(false);
    const inputValue = ref('');

    const isShowError = ref(false);
    let errorMessage = ref('');

    const onInput = () => {
      inputValue.value = event.target.value;
      if (!_isEmpty(props.validationRules)) validateField();
      isShowError.value = true;
      if (props.modelValue === inputValue.value) return;
      context.emit(EVENTS.updateModelValue, inputValue.value);
    };

    const validateField = () => {
      const validateResult = validate(inputValue.value, props.validationRules);
      errorMessage.value = validateResult.errorMessage;
      context.emit('change-valid', {
        fieldName: props.fieldName,
        isValid: validateResult.isValid,
      });
    };
    watchEffect(() => {
      if (!props.modelValue) return;
      else {
        inputValue.value = props.modelValue;
      }
    });
    watchEffect(() => {
      if (!props.isValidate) return;
      isShowError.value = true;
      if (!_isEmpty(props.validationRules)) validateField();
    });

    return {
      isShowPassword,
      isOnInput,
      onInput,
      isShowError,
      errorMessage,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables';
@import '@/scss/mixins';

.textarea-field {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 1rem;

  &__error-message {
    font-weight: 100;
    color: $col-error;
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: 0.04rem;

    &-wrp {
      position: absolute;
      top: 14.5rem;
    }

    @include respond-to($x-small) {
      font-size: 1.2rem;

      &-wrp {
        top: 12rem;
      }
    }
  }

  &__label {
    color: $col-label;
    font-size: 1.5rem;
    font-weight: 100;

    &.error {
      color: $col-error;
    }

    transition: 1ms;
  }

  &__input {
    background-color: $col-app-background-alternative;
    //font-weight: 100;
    color: $col-text;
    //font-size: 1.5rem;
    height: 13.8rem;
    border: 0;
    //TODO (check if wrong)
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2rem;

    &::placeholder {
      font-weight: 100;
      color: $col-text;
      font-size: 1.5rem;
    }

    &-wrp {
      display: flex;
      justify-content: space-between;
      border: $input-border;
      border-radius: 1.2rem;
      background-color: $col-app-background-alternative;
      padding: 0.6rem 1rem;

      &.active {
        border: $input-border-active;
      }

      &.error {
        border: $input-border-error;
      }

      &.without_border {
        border: 0;
      }
    }

    &:focus {
      &::placeholder {
        color: $col-placeholder;
      }
    }

    &-icon {
      &-btn {
        background: none;
        border: 0;
        width: 7%;
      }

      &-wrp {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &.error {
      &::placeholder {
        color: $col-error;
      }

      &:focus {
        &::placeholder {
          color: $col-placeholder;
        }
      }
    }
  }
}

@media (max-width: $sidebar-hide-bp) {
  .textarea-field__input,
  .textarea-field__label {
    font-size: 1.3rem;
    height: 11.4rem;

    &::placeholder {
      font-size: 1.3rem;
    }
  }

  .textarea-field__input-wrp {
    border-radius: 0.8rem;
  }
}
</style>
