import axiosInstance from './api';
import AuthService from './auth.service';
import TokenService from './token.service';
const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      //preventing infinite loop (/identity/v1/auth  for refresh request)
      if (originalConfig.url !== '/identity/v1/auth' && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          if (!TokenService.getLocalAccessToken()) {
            return;
          }
          try {
            const rs = await axiosInstance.post('/identity/v1/auth/refresh', {
              refresh_token: TokenService.getLocalRefreshToken(),
            });
            const new_token = rs.data;

            store.dispatch('auth/refreshToken', new_token);

            TokenService.updateLocalToken(new_token);

            return axiosInstance(originalConfig);
          } catch (_error) {
            await AuthService.logout();
            return Promise.reject(_error);
          }
        } else if (err.response.status === 403) {
          if (!originalConfig._retry) {
            // First 403 error, retry with refreshed access token
            originalConfig._retry = 1;

            try {
              const rs = await axiosInstance.post('/identity/v1/auth/refresh', {
                refresh_token: TokenService.getLocalRefreshToken(),
              });
              const new_token = rs.data;

              store.dispatch('auth/refreshToken', new_token);

              TokenService.updateLocalToken(new_token);

              return axiosInstance(originalConfig);
            } catch (error) {
              return Promise.reject(error);
            }
          } else if (originalConfig._retry === 1) {
            // Second 403 error, show notification
            originalConfig._retry = 2;

            return Promise.reject(err);
          }
        }
      }
      return Promise.reject(err);
    }
  );
};

export default setup;
