export function useAmountConversion() {
  const convertAmountToServer = (value) => value * 10 ** 4;
  const convertAmountFromServer = (value) => {
    return Math.round((value / 10 ** 4) * 100) / 100;
  };

  return {
    convertAmountToServer,
    convertAmountFromServer,
  };
}
