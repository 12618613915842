<template>
  <div class="checkbox-field" :class="{ active: modelValue }">
    <input
      class="checkbox-field__checkbox"
      type="checkbox"
      :checked="modelValue"
      @change="onChange"
    />

    <div class="checkbox-field__pointer" :class="{ active: modelValue }" />
  </div>

  <div v-if="isShowError" class="error-message-wrp">
    <span class="error-message">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
const EVENTS = {
  updateModelValue: 'update:modelValue',
};
import { ref, watchEffect } from 'vue';
import { validate } from '@/js/helpers/validate/validator.js';
import _isEmpty from 'lodash/isEmpty';

export default {
  props: {
    modelValue: { type: Boolean, default: false },
    isValidate: { type: Boolean, default: false },
    validationRules: { type: Object, default: () => {} },
    isDisabled: { type: Boolean, default: false },
    fieldName: { type: String, default: '' },
  },
  emits: ['change-valid'],

  setup(props, context) {
    const eventValue = ref(false);
    const onChange = () => {

      if (props.isDisabled) return;
      isShowError.value = true;
      eventValue.value = !props.modelValue;
      validateField();
      context.emit(EVENTS.updateModelValue, eventValue.value);
    };

    const isShowError = ref(false);
    const errorMessage = ref('');

    const validateField = () => {
      const validateResult = validate(eventValue.value, props.validationRules);
      errorMessage.value = validateResult.errorMessage;
      context.emit('change-valid', {
        fieldName: props.fieldName,
        isValid: validateResult.isValid,
      });
    };
    watchEffect(() => {
      if (!props.isValidate) return;
      isShowError.value = true;
      if (_isEmpty(props.validationRules)) return;
      validateField();
    });

    return { onChange, errorMessage, isShowError };
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables';
@import '@/scss/mixins';

.checkbox-field {
  width: 4.5rem;
  height: 2.2rem;
  background-color: $_checkboxFieldNoActiveBg;
  border-radius: 1.2rem;
  display: flex;
  justify-content: flex-start;

  &.active {
    background-color: $_checkboxFieldActiveBg;
    justify-content: end;
  }

  @include respond-to($tablet) {
    width: 3.8rem;
    height: 1.8rem;
  }

  &__checkbox {
    width: 4.5rem;
    height: 2.2rem;
    position: absolute;
    opacity: 0;
    cursor: pointer;

    @include respond-to($tablet) {
      width: 3.8rem;
      height: 1.8rem;
    }
  }

  &__pointer {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    margin: 0.2rem;
    background-color: $_checkboxFieldPointerBg;

    @include respond-to($tablet) {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
}

.error-message {
  font-weight: 100;
  color: $col-error;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0.04rem;

  &-wrp {
    position: absolute;
    margin-top: 4rem;
  }

  @include respond-to($tablet) {
    font-size: 1.2rem;

    &-wrp {
      position: absolute;
      margin-top: 3.6rem;
    }
  }
}
</style>
