import AuthService from '@/js/services/auth.service';
import UserService from '@/js/services/user.service';
import router from '@/router/index';
const user = JSON.parse(localStorage.getItem('user'));
const private_data = JSON.parse(localStorage.getItem('private_data'));
const initialState = user?.access_token
  ? { status: { loggedIn: true }, user, private_data }
  : { status: { loggedIn: false }, user: {} };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    refreshToken({ commit }, user) {
      commit('refreshToken', user);
    },
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        (error) => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },

    register({ commit }, user) {
      UserService.setUserLogin(user.login);
      return AuthService.register(user).then(
        (response) => {
          commit('registerSuccess', user.login);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    },
    setVerificationStatus({ commit }, status) {
      commit('verificationStatus', status);
    },
    setPrivateData({ commit }, private_data) {
      commit('setPrivateData', private_data);
    },
    logout({ commit }) {
      commit('logout');
      AuthService.logout();
    },
  },

  mutations: {
    refreshToken(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = {};
    },
    verificationStatus(state, status) {
      state.user.verificationStatus = status;
    },
    registerSuccess(state, login) {
      state.status.loggedIn = false;
      state.user.login = login;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    setPrivateData(state, private_data) {
      state.private_data = private_data;
    },
    logout(state) {
      state = Object.assign(state, {
        status: { loggedIn: false },
        user: {},
        private_data: {},
      });
    },
  },
  getters: {
    isLoggedIn(state) {
      return state.status.loggedIn;
    },
    getPrivateData(state) {
      return state?.private_data;
    },
  },
};
