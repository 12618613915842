class UserService {
  getKycProceedStatus() {
    return JSON.parse(localStorage.getItem('isKycPreceeded'));
  }
  setKycProceedStatus(isKycPreceeded) {
    localStorage.setItem('isKycPreceeded', JSON.stringify(isKycPreceeded));
  }

  setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }
  removeIsKycProceeded() {
    localStorage.removeItem('isKycPreceeded');
  }
}

export default new UserService();
