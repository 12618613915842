import api from "@/js/services/api";
 export const sendRequest = async (id) => {
  try {
    const response = await api.get(`identity/v1/roles/${id}`);

    if (response.data.role!==3){
      return "role changed"
    }
    return "role unverified"
  } catch (error) {
    return error.response.status
  }

};
