class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user?.refresh_token;
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user?.access_token;
  }

  updateLocalToken(token) {
    let user = JSON.parse(localStorage.getItem('user'));
    user = token;
    localStorage.setItem('user', JSON.stringify(user));
  }
}

export default new TokenService();
