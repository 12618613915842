<template lang="html">
  <div v-if="!isLoggedIn" class="unauthorized">
    <div
      class="unauthorized__wrapper"
      :class="{ 'slide-in': isOpen, 'slide-out': !isOpen }"
    >
      <div class="container">
        <div class="unauthorized__content">
          <div class="unauthorized__logo-small">
            <img
              src="@/assets/branding/logo/unauthorized-logo-small.png"
              class="unauthorized__logo-img"
              alt="REIT logo"
            />
          </div>
          <div class="unauthorized__nav-panel-outer">
            <div class="unauthorized__nav-panel">
              <div class="unauthorized__nav-panel-wrp">
                <router-link
                  class="unauthorized__nav-panel-link"
                  active-class="unauthorized__nav-panel-link-active"
                  :to="vueRoutes.marketplace"
                >
                  {{ $t('settings.nav-bar.marketplace') }}
                </router-link>
              </div>

              <div class="unauthorized__nav-panel-wrp">
                <router-link
                  class="unauthorized__nav-panel-link"
                  active-class="unauthorized__nav-panel-link-active"
                  :to="vueRoutes.about"
                >
                  {{ $t('settings.nav-bar.about') }}
                </router-link>
              </div>

              <div class="unauthorized__nav-panel-wrp">
                <router-link
                  class="unauthorized__nav-panel-link"
                  active-class="unauthorized__nav-panel-link-active"
                  :to="vueRoutes.FAQ"
                >
                  {{ $t('settings.nav-bar.faq-link') }}
                </router-link>
              </div>
              <div class="unauthorized__nav-panel-wrp">
                <router-link
                  class="unauthorized__nav-panel-link"
                  active-class="unauthorized__nav-panel-link-active"
                  :to="vueRoutes.contacts"
                >
                  {{ $t('settings.nav-bar.contacts') }}
                </router-link>
              </div>
              <div class="unauthorized__re-tokens">
<!--                <div class="unauthorized__re-tokens-item">-->
<!--                  <div class="unauthorized__re-tokens-title">-->
<!--                    {{ $t('re-tokens.amount') }}-->
<!--                  </div>-->
<!--                  <div class="unauthorized__re-tokens-balance">-->
<!--                    {{ formattedTotalRe }}-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="unauthorized__re-tokens-item">-->
<!--                  <div class="unauthorized__re-tokens-title">-->
<!--                    {{ $t('re-tokens.for-buy') }}-->
<!--                  </div>-->
<!--                  <div class="unauthorized__re-tokens-balance">-->
<!--                    {{ formattedRE  }}-->
<!--                  </div>-->
<!--                </div>-->
                <ReTokens :is-header="true"/>
              </div>
            </div>
          </div>
          <div class="unauthorized__switcher-wrp">
            <language-switcher />
            <router-link class="unauthorized__login" :to="vueRoutes.login">
              <div class="unauthorized__login-text">
                {{ $t('unauthorized.login') }}
              </div>
              <div class="unauthorized-image-login">
                <icon :name="'unauthorized-login'" />
              </div>
              <div class="unauthorized-image-entry">
                <icon :name="'unauthorized-entry'" />
              </div>
            </router-link>
          </div>
          <div class="unauthorized__nav-panel-mobile">
            <BurgerMenu @is-open="isOpen = !isOpen">
              <div class="unauthorized__nav-panel-wrp">
                <router-link
                  class="unauthorized__nav-panel-link"
                  active-class="unauthorized__nav-panel-link-active"
                  :to="vueRoutes.marketplace"
                >
                  {{ $t('settings.nav-bar.marketplace') }}
                </router-link>
              </div>

              <div class="unauthorized__nav-panel-wrp">
                <router-link
                  class="unauthorized__nav-panel-link"
                  active-class="unauthorized__nav-panel-link-active"
                  :to="vueRoutes.about"
                >
                  {{ $t('settings.nav-bar.about') }}
                </router-link>
              </div>

              <div class="unauthorized__nav-panel-wrp">
                <router-link
                  class="unauthorized__nav-panel-link"
                  active-class="unauthorized__nav-panel-link-active"
                  :to="vueRoutes.FAQ"
                >
                  {{ $t('settings.nav-bar.faq-link') }}
                </router-link>
              </div>
              <div class="unauthorized__nav-panel-wrp">
                <router-link
                  class="unauthorized__nav-panel-link"
                  active-class="unauthorized__nav-panel-link-active"
                  :to="vueRoutes.contacts"
                >
                  {{ $t('settings.nav-bar.contacts') }}
                </router-link>
              </div>
              <div class="unauthorized__re-tokens unauthorized__re-tokens-burger">
<!--                <div class="unauthorized__re-tokens-item unauthorized__re-tokens-item-burger">-->
<!--                  <div class="unauthorized__re-tokens-title">-->
<!--                    {{ $t('re-tokens.amount') }}-->
<!--                  </div>-->
<!--                  <div class="unauthorized__re-tokens-balance">-->
<!--                    {{ formattedTotalRe }}-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="unauthorized__re-tokens-item unauthorized__re-tokens-item-burger">-->
<!--                  <div class="unauthorized__re-tokens-title">-->
<!--                    {{ $t('re-tokens.for-buy') }}-->
<!--                  </div>-->
<!--                  <div class="unauthorized__re-tokens-balance">-->
<!--                    {{ formattedRE }}-->
<!--                  </div>-->
<!--                </div>-->
                <ReTokens />
              </div>
              <template #footer>
                <div class="unauthorized__switcher-wrp-mobile">
                  <language-switcher color="#196DEA" />
                  <router-link
                    class="unauthorized__login"
                    :to="vueRoutes.login"
                  >
                    <div class="unauthorized-image-login">
                      <icon :name="'unauthorized-login-mobile'" />
                    </div>
                    <div class="unauthorized__login-text">
                      {{ $t('unauthorized.login') }}
                    </div>
                  </router-link>
                </div>
              </template>
            </BurgerMenu>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import BurgerMenu from '@/vue/components/common/BurgerMenu.vue';
import icon from '@/vue/components/common/Icon.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { languageSwitcher } from '@/js/helpers/languageSwitcher';
import LanguageSwitcher from '@/vue/components/common/LanguageSwitcher.vue';
import { vueRoutes } from '@/router/router-const';
import {formatBalanceRE} from "@/js/filters/formatBalance";
import ReTokens from "@/vue/components/common/ReTokens.vue";
export default {
  name: 'UnauthorizedPanel',
  components: {
    icon,
    LanguageSwitcher,
    BurgerMenu,
    ReTokens,
  },
  setup() {
    const isOpen = ref(false);
    const store = useStore();
    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);
    const formattedRE = computed(() => {
      const balanceRE = store.getters['balances/getBalances'].balanceRE;
      return formatBalanceRE(balanceRE);
    });
    const formattedTotalRe = computed(() => {
      const balanceRE = store.getters['balances/getBalances'].balanceRE;
      return formatBalanceRE(balanceRE);
    });

    return { languageSwitcher, isLoggedIn, vueRoutes, isOpen, formattedRE, formattedTotalRe };
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/app.scss';

.unauthorized {
  background-color: $_oceanBlue;
  width: 100%;
  position: fixed;
  top: 0;
  height: $unauthorized-panel-height;
  z-index: $z-index-unauthorized-panel;
  &__language {
    display: flex;
  }
  &__wrapper {
    height: 100%;
    margin: 0 calc($sidebar-width / 2);
    display: flex;
    align-items: center;

    @include respond-to($x-medium) {
      margin: 0;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    display: inline-flex;
  }

  &__login {
    min-width: 10rem;
    background-color: #f4f4f4;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    color: #333333;
    font-size: 1.5rem;
    transition: all 0.3s;

    @include respond-to($sidebar-hide-bp) {
      background-color: unset;
      border-radius: 0;
    }

    &-image {
      display: none;
    }

    &-text {
      margin: 0 0.6rem 0 0;
      @include respond-to($sidebar-hide-bp) {
        color: #196dea;
        display: flex;
        align-items: center;
        margin: 0 0 0 0.6rem;
      }
    }

    &:hover {
      color: #196dea;
      background-color: #fff;

      .unauthorized-image-login {
        stroke: #196dea;
      }
    }
  }

  &-image {
    &-login {
      display: flex;
      stroke: #333333;
      transition: all 0.3s;
    }
    &-entry {
      display: none;
      @include respond-to($small) {
        display: block;
        width: 25px;
        height: 25px;
      }
    }
  }
}
.unauthorized__logo-img {
  object-fit: contain;
  image-rendering: -webkit-optimize-contrast; /* Safari */
  image-rendering: optimizeQuality;
  height: 6.5rem;
  @include respond-to($sidebar-hide-bp) {
    height: 4rem;
  }
}
.unauthorized__logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.unauthorized__nav-panel-mobile {
  display: none;
  @include respond-to($sidebar-hide-bp) {
    display: block;
  }
}
.unauthorized__nav-panel {
  gap: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  white-space: nowrap; /* Предотвращает перенос текста на новую строку */
  //margin: 0 3rem;
  overflow: hidden;
  @include respond-to($x-medium) {
    gap: 1.5rem;
  }
  @include respond-to($sidebar-hide-bp) {
    display: none;
  }
}
.unauthorized__nav-panel-wrp {

}
.unauthorized__nav-panel-link {
  font-size: 1.6rem;

  @include respond-to($sidebar-hide-bp) {
    color: $col-mobile-burger;
    font-size: 2.2rem;
    padding: 0 4rem;
    display: flex;
    align-items: center;
    height: 7rem;
  }
}
.unauthorized__nav-panel-link-active {
  @include respond-to($sidebar-hide-bp) {
    background-color: $col-select;

    /* align-items: center; */
    display: flex;
    align-items: center;
    color: $col-primary;
  }
}
.unauthorized__switcher-wrp {
  display: flex;
  gap: 2rem;
  @include respond-to($sidebar-hide-bp) {
    display: none;
  }
}
.unauthorized__switcher-wrp-mobile {
  @include respond-to($sidebar-hide-bp) {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    padding: 0 4rem 6rem 4rem;
    width: 100%;
  }
  @media (max-height: 720px) {
      position: unset;
      margin-top: 2rem;
    }
}

.unauthorized__logo-text {
  @include respond-to($x-small) {
    width: 7rem;
  }
}
img {
  height: fit-content;
}
.slide-in {
  @include respond-to($sidebar-hide-bp) {
    animation: slideIn 0.7s forwards !important;
  }
}
.slide-out {
  @include respond-to($sidebar-hide-bp) {
    animation: slideOut 0.7s forwards !important;
  }
}

@keyframes slideIn {
  from {
    background-color: $_oceanBlue;
  }
  to {
    background-color: $bg-mobile-burger;
    border-bottom: 1px solid #bfcbd8;
  }
}

@keyframes slideOut {
  from {
    background-color: $bg-mobile-burger;
  }
  to {
    background-color: $_oceanBlue;
  }
}

.unauthorized__logo-small {
  width: 100px;
}
.unauthorized__re-tokens {
  display: flex;
  gap: 2rem;
}
.unauthorized__re-tokens-item {
  border-left: 1px solid #196DEA;
  border-radius: 6px;
  background-color: white;
  flex: 1 0 0;
  padding: 0.6rem 1rem;
}
.unauthorized__re-tokens-title{
  white-space: nowrap;
  color: #858585;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.15px;
}
.unauthorized__re-tokens-balance {
  color: #333;
  font-size: 16px;
  font-weight: 400;
}
//.unauthorized__re-tokens-item-burger {
//  border-radius: 0;
//}
.unauthorized__re-tokens-burger {
  flex-direction: column;
  gap: 2rem;
  margin: 4rem 4rem;
}
.unauthorized__nav-panel-outer {
  overflow-x: auto;
  margin: 0 3rem;
}
</style>
