import store from '@/vuex/index';
import { vueRoutes } from "@/router/router-const";
const isLoggedIn = store.getters['auth/isLoggedIn'];

export default [
  {
    path: '/authorization',
    name: vueRoutes.authorization.name,
    redirect: vueRoutes.login,
  },
  {
    path: '/authorization/login',
    name: vueRoutes.login.name,
    components: {
      default: () => import('@/vue/components/pages/Authorization/Login'),
    },
    meta: {
      requiredVisitor: true,
    },
  },
  {
    path: '/authorization/registration',
    name: vueRoutes.registration.name,
    components: {
      default: () =>
        import('@/vue/components/pages/Authorization/Registration'),
    },
    meta: {
      requiredVisitor: true,
    },
  },
  {
    path: '/authorization/recovery',
    name: vueRoutes.recovery.name,
    components: {
      default: () => import('@/vue/components/pages/Recovery/Recovery'),
    },
    meta: {
      requiredVisitor: true,
    },
  },
  {
    path: '/recovery/seed',
    name: vueRoutes.recoveryPrivateData.name,
    components: {
      default: () =>
        import('@/vue/components/pages/Recovery/RecoveryPrivateData'),
    },
    meta: {
      requiredAuth: true,
      recovery: true,
    },
  },
  {
    path: '/authorization/registration/code',
    name: vueRoutes.registrationCode.name,
    components: {
      default: () =>
        import('@/vue/components/pages/Authorization/RegistrationCode'),
    },
    meta: {
      requiredVisitor: true,
    },
  },
  {
    path: '/authorization/mnemonic',
    name: vueRoutes.mnemonic.name,
    beforeEnter: mnemonicGuard,
    components: {
      default: () => import('@/vue/components/pages/Authorization/Mnemonic'),
    },
    meta: {
      requiredAuth: true,
      wallet: true,
    },
  },
  {
    path: '/authorization/kyc',
    name: vueRoutes.authKYC.name,
    beforeEnter: kycGuard,
    components: {
      default: () => import('@/vue/components/pages/Authorization/AuthKYC'),
    },
    meta: {
      requiredAuth: true,
      toProceedKyc: true,
      kyc: true,
    },
  },
];
function mnemonicGuard(to, from, next) {
  if (localStorage.getItem('private_data') && isLoggedIn) {
    next('/market');
    // next(vueRoutes.marketplace.name);
  } else {
    next();
  }
}

function kycGuard(to, from, next) {
  if (localStorage.getItem('isKycPreceeded') && isLoggedIn) {
    next('/market');
    // next(vueRoutes.marketplace.name);
  } else {
    next();
  }
}
