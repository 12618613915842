<template>
  <button class="logout-btn" @click="logout">
    <icon name="logout" />
    <div class="logout-btn__text">
      {{ $t('sidebar.logout') }}
    </div>
  </button>
</template>
<script>
import icon from '@/vue/components/common/Icon.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import AuthService from '@/js/services/auth.service';

export default {
  name: 'Logout',
  components: {
    icon,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const logout = () => {
      store.dispatch('logout');
      location.reload();
    };

    return {
      store,
      logout,
      router,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';

.logout-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $sidebar-bg-col;

  &__text {
    margin-left: 0.6rem;
    font-size: 1.3rem;
  }
}
</style>
