<template>
  <div
    v-click-outside="() => (isOpen = false)"
    class="dropdown-field"
    :class="{ active: isOpen && !isDisabled }"
    @click="isOpen = !isOpen"
  >
    <div
      class="dropdown-field__toggle"
      :class="{ noWidth: isIcon === true }"
    >
      <div
        class="dropdown-field__toggle-selected-wrp"
        :class="{ error: isShowError && errorMessage }"
        :style="{ backgroundColor: isIcon === true ? bgColor : 'white' }"
      >
        <slot v-if="isIcon === true" />
        <div
          v-else
          class="dropdown-field__toggle-selected"
          :class="{ error: isShowError && errorMessage }"
        >
          {{ label ? $t(label) || label : placeholder }}
        </div>
        <div
          class="dropdown-field__toggle-chevron"
          :class="{ error: isShowError && errorMessage }"
        >
          <span />
          <span />
        </div>
      </div>
      <div class="dropdown-field__toggle-list" :class="{ isFirst: isFirst }">
        <div
          v-for="opt in options"
          :key="opt.label"
          class="dropdown-field__toggle-list-option"
          :class="{activeString : getActiveString(opt.label) }"
          @click="changeValue(opt)"
        >
          {{ $t(opt.label) || opt.label }}
        </div>
      </div>
    </div>

    <div v-if="isShowError && !isOpen" class="error-message-wrp">
      <span class="error-message">
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>

<script>
const EVENTS = {
  updateModelValue: 'update:modelValue',
  onChange: 'on-change',
};
import { ref, watchEffect } from 'vue';
import { validate } from '@/js/helpers/validate/validator';
import _isEmpty from 'lodash/isEmpty';
import vClickOutside from 'click-outside-vue3';
import i18n from "@/js/i18n/i18n";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    placeholder: { type: [String, Number], default: '' },
    options: { type: Array, default: () => [] },
    /**
     Options array should be such a structure:
     [
       { label: 'String', value: Any },
       { label: 'String', value: Any }
     ]
     */
    isValidate: { type: Boolean, default: false },
    validationRules: { type: Object, default: () => {} },
    isDisabled: { type: Boolean, default: false },
    fieldName: { type: String, default: '' },
    modelValue: { default: '' },
    isFirst: { type: Boolean, default: false },
    bgColor: { type: String, default: '' },
    isIcon: { type: Boolean, default: false }
    // maxWidth: {type: String, default: '19rem' }
  },
  emits: ['update:modelValue', 'on-change', 'change-valid'],

  setup(props, context) {

    // return t(
    //   array.find((t) => t.value.toString() === value.toString()).label
    // );
    const { t } = i18n.global;
    const isOpen = ref(false);
    const label = ref('');
    const getActiveString = (label) => {
      return  t(label) === props.placeholder
    }
    const changeValue = (opt) => {
      label.value = opt.label;
      validateField();
      context.emit(EVENTS.updateModelValue, opt.value);
      context.emit(EVENTS.onChange);
    };

    const isShowError = ref(false);
    const errorMessage = ref('');

    const validateField = () => {
      const validateResult = validate(label.value, props.validationRules);
      errorMessage.value = validateResult.errorMessage;
      context.emit('change-valid', {
        fieldName: props.fieldName,
        isValid: validateResult.isValid,
      });
    };
    watchEffect(() => {
      if (props.labelFromParent) {
        label.value = props.labelFromParent;
      }
    });
    watchEffect(() => {
      if (!props.modelValue) label.value = '';
      if (!props.isValidate) return;
      isShowError.value = true;
      if (_isEmpty(props.validationRules)) return;
      validateField();
    });


    return {
      isOpen,
      label,
      changeValue,
      errorMessage,
      isShowError,
      getActiveString,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables';
@import '@/scss/mixins';

.dropdown-field {
  position: relative;
  display: flex;
  justify-content: space-between;

  &__toggle {
    flex-direction: column;
    position: relative;
    padding-left: 0.1rem;
    cursor: pointer;
    width: 100%;
    //max-width: 21rem;
    //max-width: 20rem;
    &.noWidth {
      width: 100%;
    }
  }

  &__toggle-selected {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #929fb1;
    //width: 70%;
    transition: color $transition-duration linear;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-clamp: 1;
    overflow: hidden;

    @include respond-to($small) {
      font-size: 1.4rem;
    }

    &.error {
      color: $col-error;
    }

    &-wrp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 1.5rem;
      color: $col-label;
      padding: 0 1.5rem;
      height: 4rem;
      font-size: 1.8rem;
      background-color: #fff;
      font-weight: 500;
      //width: fit-content;

      @media (max-width: $sidebar-hide-bp) {
        height: 3.6rem;
      }

      &.error {
        border: 0.1rem solid $col-error;
      }
    }

    &::after {
      content: '';
      display: block;
      width: 0%;
      height: 2px;
      background-color: $col-text-inactive;
      transition: width $transition-duration linear;
    }
  }

  &__toggle-chevron {
    position: relative;
    top: 0.1rem;
    min-width: 1.7rem;
    height: 100%;
    margin-left: 0.5rem;
    transition: transform $transition-duration linear;

    & span {
      position: absolute;
      top: 42%;
      display: block;
      height: 2px;
      width: 1.025rem;
      background-color: #929fb1;
      transition: background-color $transition-duration linear;

      &:first-child {
        left: 0;
        transform: rotate(45deg);
      }

      &:last-child {
        right: 0;
        transform: rotate(-45deg);
      }
    }

    &.error span {
      background-color: $col-error;
    }
  }

  &.active &__toggle-chevron {
    transform: rotate(90deg);
  }

  &.active &__toggle-chevron span {
    background-color: $_arrowBlue;
  }

  &__toggle-list {
    position: absolute;
    text-align: center;
    z-index: 3;
    flex-direction: column;
    top: 5rem;
    right: 0;
    border: 1px solid #b2cbef;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    display: none;
    color: $col-nav-bar-active;
    background-color: #fff;
    font-size: 1.6rem;
    font-weight: 400;
    transition: height $transition-duration linear;
    max-height: 30rem;
    overflow: auto;
    overflow-x: hidden;
    border-radius: 1.5rem 1.5rem 1.5rem 1.5rem;

    @include respond-to($xl-small) {
      left: 0;
      right: auto;
      // min-width: 30rem;
      // width: fit-content;
    }

    @media screen and (max-height: 350px) {
      left: 0;
      right: auto;
    }

    &.isFirst {
      right: auto;
    }
    // transform: translateX(-1px);

    &-option {
      width: 100%;
       //min-width: max-content;
      min-width: 100%; //check
      background-color: $dropdown-field-bg;
      min-height: 4rem;
      display: flex;
      align-items: center;
      color: $col-text;
      padding: 0 3rem;
      font-weight: 500;
      font-size: 1.8rem;

      &:hover {
        color: $col-primary;
        background-color: $dropdown-field-hover-bg;
      }

      @include respond-to($xl-small) {
        font-size: 1.3rem;
      }
    }
  }

  &.active &__toggle-list {
    display: flex;
    // padding-left: 0.1rem;
  }
}

.error-message {
  display: none;
  font-weight: 100;
  color: $col-error;

  &-wrp {
    position: absolute;
    z-index: 0;
    width: 21rem;
  }

  @include respond-to($tablet) {
    display: flex;
    font-size: 1.2rem;

    &-wrp {
      margin-top: 3.8rem;
    }
  }
}

.dropdown-field__toggle-list-option {
  width: max-content;
}
.activeString {
  color: $col-selected-filter;
}
//.activeString::before {
//  content: "•"; /* Символ маркера */
//  padding-right: 0.5rem;
//}
</style>
