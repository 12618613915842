/**

 * @module crypto
 * @description
 * this module is used to generate mnemonic phrase, restore seed from it
 * and restore public and private key from seed
 */

import * as bip39 from 'bip39';
import CryptoJS from 'crypto-js';
import { hdkey } from 'ethereumjs-wallet';

/**
 * entripyBits
 * @type {number}
 */
const entripyBits = '256';
/**
 * @function generateMnemonic
 * Generate mnemonic phrase
 * @params {} -has now parameters
 */

export const generateMnemonic = () => {
  return bip39.generateMnemonic(entripyBits);
};

/**
 * @function getSeedFromMnemonicInBase64
 * Getting seed from mnemonic and secret key
 * @param {string} mnemonic - mneonic phrase from @function generateMnemonic
 * @param {string} key - secret key wich represents wallet password
 * @returns {string} - seed  in base64 format
 */
export const getSeedFromMnemonicInBase64 = async (mnemonic, key) => {
  return seedToBase64(await bip39.mnemonicToSeed(mnemonic, key));
};

export const seedToBase64 = (seed) => {
  return Buffer.from(seed).toString('base64');
};

/**
 * @function encryptAES
 * Encrypt a derived seed  with a given pin and return it in Base64 form
 * @param {string} text - wich is a seed
 * @param {string} key  - wich is a secret key
 * @returns {string} - encrypted data
 */

export const encryptAES = (text, key) => {
  return CryptoJS.AES.encrypt(text, key).toString();
};

/**
 * @function decryptAES
 * Decrypt an encrypted message
 * @example const decr = crypto.decryptAES(encodedSeed.value, password.value)
 * @param {string} encryptedBase64 encrypted data in base64 format
 * @param {string} key The secret key
 * @return {string} The decrypted content
 */

export const decryptAES = (encryptedBase64, key) => {
  const decrypted = CryptoJS.AES.decrypt(encryptedBase64, key);
  if (decrypted) {
    try {
      const str = decrypted.toString(CryptoJS.enc.Utf8);
      if (str.length > 0) {
        return str;
      } else {
        return 'invalid_password';
      }
    } catch (e) {
      return 'invalid_password';
    }
  }
  return 'unexpected error';
};


/**
 * @function getHdkey
 * getHDkey
 * @param {string} seed in base64 format
 * @returns {object} - hdkey
 */
export const getHDkey = (seed) => {
  return hdkey.fromMasterSeed(seed);
};
/**
 * @function getPublickKey
 * @description Get public key
 * @param {object} hdwallet
 * @returns {string} - wallet address in hexadecimal format
 */
export const getPublickKey = (hdwallet) => {
  const path = "m/44'/60'/0'/0/0";
  const wallet = hdwallet.derivePath(path).getWallet();
  return wallet.getAddressString();
};

/**
 * @function getPrivateKey
 * Get private key
 * @param {object} hdwallet
 * @returns {string} - private key in hexadecimal format
 */
export const getPrivateKey = (hdwallet) => {
  const path = "m/44'/60'/0'/0/0";
  const wallet = hdwallet.derivePath(path).getWallet();
  return wallet.getPrivateKeyString();
};
