<template>
  <div :class="typeClass" class="notification" role="alert">
    <template v-if="te(`errors.${notification.message}`)">
      <div class="notification__code">
        {{ $t(`errors.${notification.message}.title`) }}
      </div>
      <div class="notification__message">
        {{ $t(`errors.${notification.message}.message`) }}
      </div>
    </template>

    <template v-else-if="notification.type === 'success'">
      <div class="notification__code">
        {{ $t(`success.${notification.message}.title`) }}
      </div>
      <div class="notification__message">
        {{ $t(`success.${notification.message}.message`) }}
      </div>
    </template>
    <template v-else>
      <div class="notification__code">
        {{ $t(`errors.fallback.title`) }}
      </div>
      <div class="notification__message">
        {{ $t(`errors.fallback.message`) }}
      </div>
    </template>
    <button
      type="button"
      class="notification__close"
      data-dismiss="alert"
      aria-label="Close"
      @click="removeNotification(notification)"
    >
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.06044 6.35131L11.7803 1.37692C12.0732 1.0681 12.0732 0.567413 11.7803 0.258621C11.4873 -0.0501962 11.0123 -0.0501962 10.7193 0.258621L5.99998 5.23354L1.28068 0.258621C0.987704 -0.0501962 0.512692 -0.0501962 0.219735 0.258621C-0.0732216 0.567438 -0.073245 1.06813 0.219735 1.37692L4.93953 6.35131L0.219735 11.3257C-0.073245 11.6346 -0.073245 12.1352 0.219735 12.444C0.512715 12.7528 0.987727 12.7528 1.28068 12.444L5.99998 7.46909L10.7193 12.444C11.0123 12.7528 11.4873 12.7528 11.7802 12.444C12.0732 12.1352 12.0732 11.6345 11.7802 11.3257L7.06044 6.35131Z"
          fill="white"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default defineComponent({
  props: {
    notification: {
      type: Object,
      validator(value) {
        // The value must match one of these strings
        return ['success', 'warning', 'danger'].includes(value.type);
      },
    },
  },

  setup(props) {
    const { te } = useI18n();
    const store = useStore();

    const typeClass = computed(() => {
      return `notification--${props.notification.type}`;
    });

    const removeNotification = (notification) => {
      store.dispatch('notification/removeNotification', notification);
    };

    return {
      te,
      typeClass,
      removeNotification,
    };
  },
});
</script>
<style scoped lang="scss">
@import '@/scss/variables.scss';

.notification {
  max-width: 33.5rem;
  min-height: 5rem;
  box-shadow: 0px 0px 6px 2px rgba(147, 181, 191, 0.3);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  color: $notification-col;
  position: relative;
  z-index: $z-index-notification;
  padding: 1.5rem 3.5rem 2rem 2rem;

  &--warning {
    background-color: $allert-bgc;
  }

  &--success {
    background-color: $success-bgc;
  }

  &__close {
    width: 2.6rem;
    height: 2.6rem;
    font-size: 2.5rem;
    font-weight: 300;
    border-radius: 50%;
    border: 3px solid $notification-col;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  &__message {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.2rem;
  }

  &__code {
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
  }
}
</style>
