export const images = {
  namespaced: true,
  state: {
    avatar: {},
  },
  actions: {
    addAvatar({ commit }, avatar) {
      commit('SET_AVATAR', avatar);
    },
  },
  mutations: {
    SET_AVATAR(state, avatar) {
      state.avatar = avatar;
    },
  },
  getters: {
    avatar(state) {
      return state.avatar;
    },
  },
};
