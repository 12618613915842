<template>
  <a class="passport" href="#">
    <div class="passport__thumbnail">
      <img class="passport__thumbnail-avatar" :src="avatar" alt="" />
    </div>
    <div class="passport__info">
      <span class="passport__info-name">{{ $t('passport.balance') }}</span>
      <div v-for="(balance, asset) in assetBalance" :key="asset" class="passport__info-amount">
<!--        {{ asset }}:-->
        <span v-if="asset === 'balanceRE' || asset === 'totalRE'"></span>
        <span v-else>  {{ (formatBalanceTrue(balance)) + ' ' + (asset === 'RE' ? asset : asset + '!') }}</span>
      </div>
<!--      <div>-->
<!--        <div class="passport__info-amount">{{ formatBalance(usdBalance) }}</div>-->
<!--        {{usdBalance}}-->
<!--        <div class="passport__info-amount">{{ formatBalanceRE(reBalance) }}</div>-->
<!--      </div>-->
    </div>
  </a>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import {formatBalance, formatBalanceRE, formatBalanceTrue } from '@/js/filters/formatBalance';

export default {

  setup() {
    const avatar = computed(() => store.getters['images/avatar']);
    const assetBalance = computed(
      () => store.getters['balances/getBalances']
    );
    const reBalance = computed(
      () => store.getters['balances/getBalances'].RE
    )
    const store = useStore();
    // const formattedBalance = computed(() => {
    //   return formatBalance(usdBalance.value);
    // });

    return {
      avatar,
      assetBalance,
      reBalance,
      formatBalanceTrue
      // formatBalanceRE
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';

.passport {
  display: block;
  display: flex;
  align-items: center;
  padding: 1.3rem;
  font-weight: 400;
  font-size: 1.2rem;
  color: $sidebar-bg-col;

  &__info {
    margin-left: 1.8rem;
    display: flex;
    flex-direction: column;
    gap:.5rem;
    &-name {
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 1.6rem;
    }

    &-amount {
      white-space: nowrap;
      font-size: 1.2rem;
    }
  }

  &__thumbnail {
    height: 6.4rem;
    width: 6.4rem;

    &-avatar {
      border-radius: 50%;
      object-fit: cover;
      min-height: 6.4rem;
      min-width: 6.4rem;
    }
  }
}
</style>
