<template>
  <div class="mini-wallet">
    <div class="mini-wallet__avatar-wrp">
      <div class="mini-wallet__thumbnail">
        <img class="mini-wallet__thumbnail-avatar" :src="avatar" alt="" />
      </div>
    </div>

    <div class="mini-wallet__balance-wrp">
      <div class="mini-wallet__info">
        <div class="mini-wallet__info-name">{{ $t('passport.balance') }}</div>
        <div class="mini-wallet__info-amount">{{ formattedBalance }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { formatBalance } from '@/js/filters/formatBalance';

export default {
  props: { isKYC: { type: Boolean, default: false } },
  setup() {
    const avatar = computed(() => store.getters['images/avatar']);
    const usdBalance = computed(
      () => store.getters['balances/getBalances'].USD
    );
    const store = useStore();
    const formattedBalance = computed(() => {
        return formatBalance(usdBalance.value);
    });

    return {
      avatar,
      usdBalance,
      formattedBalance,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';

.mini-wallet {
  padding: 2rem;
  display: flex;
  align-items: center;

  &__balance {
    font-weight: 300;
    font-size: 1.2rem;
    color: $col-balance;

    &.kyc {
      color: $col-primary;
    }

    &-header {
      font-size: 1.4rem;
      font-weight: 300;
      color: $col-alt-input-placeholder;

      &.kyc {
        color: $col-primary;
      }
    }
  }

  &__avatar {
    &-wrp {
      margin: 0 1rem;
    }
  }
}

.mini-wallet {
  &__thumbnail {
    height: 6.4rem;
    width: 6.4rem;

    &-avatar {
      border-radius: 50%;
      object-fit: cover;
      height: 6.4rem;
      width: 6.4rem;
    }
  }
}

.mini-wallet {
  &__info {
    &-name {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ababab;
    }
    &-amount {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #196dea;
    }
  }
}
</style>
