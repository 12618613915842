<template>
  <div class="mobile-menu">
    <div
      class="mobile-menu__wrp"
      :class="{ active: isShowDetails }"
      @click="isShowDetails = !isShowDetails"
    >
      <MobileMenuNav
        @open-details="isShowDetails = !isShowDetails"
        @click.stop=""
      />
      <MobileMenuDetails
        class="mobile-menu__mobile-menu-details"
        :class="{ active: isShowDetails }"
        :is-open="isShowDetails"
        @close-details="isShowDetails = false"
        @touchstart="swipeStart($event.changedTouches[0].clientY)"
        @touchend="swipeEnd($event.changedTouches[0].clientY)"
        @click.stop=""
      />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import MobileMenuNav from '@/vue/components/MobileMenu/MobileMenuNav';
import MobileMenuDetails from '@/vue/components/MobileMenu/MobileMenuDetails';

export default {
  components: { MobileMenuNav, MobileMenuDetails },

  setup() {
    let isShowDetails = ref(false);
    const swipeEnd = (clientY) => {
      if (clientY > startClientY.value) isShowDetails.value = false;
    };

    let startClientY = ref(0);
    const swipeStart = (clientY) => {
      startClientY.value = clientY;
    };

    return { isShowDetails, swipeStart, swipeEnd };
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables';
@import '@/scss/mixins';

.mobile-menu {
  &__wrp {
    display: flex;
    justify-content: center;
    width: 100%;
    position: fixed;
    z-index: 10;

    &.active {
      height: 100vh;
      background: rgba(0, 0, 0, 0.3);
    }
  }

  &__mobile-menu-details {
    position: fixed;
    width: 30rem;
    height: auto;
    bottom: 1rem;
    transform: translateY(100%);
    transition: transform $transition-duration linear;

    &.active {
      transform: translateY(0);
    }
  }
}
</style>
