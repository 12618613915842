export const crypto = {
  namespaced: true,  // Enables namespacing for this module
  state: {
    walletAddress: '',
    walletConnected: false,
  },
  mutations: {
    setWalletAddress(state, address) {
      state.walletAddress = address;
    },
    setWalletConnected(state, isConnected) {
      state.walletConnected = isConnected;
    },
  },
  actions: {
    connectWallet({commit}) {
      if (typeof window.ethereum !== 'undefined') {

        window.ethereum.request({method: 'eth_requestAccounts'})
          .then((accounts) => {
            commit('setWalletAddress', accounts[0]);
            commit('setWalletConnected', true);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        alert('Пожалуйста, установите MetaMask для продолжения');
      }
    },
    disconnectWallet({commit}) {
      commit('setWalletAddress', '');
      commit('setWalletConnected', false);
      console.log('Wallet disconnected');
    },
    checkWalletConnection({ commit }) {
      window.ethereum.request({ method: 'eth_accounts' })
        .then((accounts) => {
          if (accounts.length > 0) {
            console.log(accounts[0], 'connect wallet')
            commit('setWalletAddress', accounts[0]);
            commit('setWalletConnected', true);
          } else {
            console.log(accounts[0], 'else connect wallet')
            commit('setWalletAddress', '');
            commit('setWalletConnected', false);
          }
        })
        .catch((error) => {
          console.error('Ошибка при проверке подключения кошелька:', error);
        });
    },

    // async createTransaction({state}, {toAddress, amount}) {
    //   if (!state.walletConnected || !state.walletAddress) {
    //     alert('Пожалуйста, подключите кошелек перед отправкой транзакции.');
    //     return;
    //   }
    //
    //   try {
    //     const transactionParams = {
    //       from: state.walletAddress,
    //       to: toAddress,
    //       value: `0x${(amount * 10 ** 18).toString(16)}`,
    //       gas: '0x5208',
    //     };
    //
    //     await window.ethereum.request({
    //       method: 'eth_sendTransaction',
    //       params: [transactionParams],
    //     });
    //
    //     console.log('Транзакция создана');
    //   } catch (error) {
    //     console.error('Ошибка при создании транзакции:', error);
    //   }
    // },
    async createTransaction({ state }, { toAddress, amount }) {
      if (!state.walletConnected || !state.walletAddress) {
        alert('Пожалуйста, подключите кошелек перед отправкой транзакции.');
        return;
      }

      try {
        const usdtContractAddress = '0x419Fe9f14Ff3aA22e46ff1d03a73EdF3b70A62ED';
        const decimals = 6; // Проверьте количество десятичных знаков для вашего токена
        const amountInWei = (amount * 10 ** decimals).toString(16);

        const transferMethodId = '0xa9059cbb';
        const paddedToAddress = toAddress.toLowerCase().replace('0x', '').padStart(64, '0');
        const paddedAmount = amountInWei.padStart(64, '0');
        const data = transferMethodId + paddedToAddress + paddedAmount;

        const transactionParams = {
          from: state.walletAddress,
          to: usdtContractAddress,
          data: data,
          gas: undefined, // MetaMask рассчитает газ
        };

        await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [transactionParams],
        });

        console.log('Транзакция создана');
      } catch (error) {
        console.error('Ошибка при создании транзакции:', error);
      }
    }



  },
}
